<template>
  <div>
    <v-app-bar
      id="home-app-bar"
      sticky
      color="secondary"
      elevation="5"
      height="80"
    >
      <v-container fluid>
        <v-row
          no-gutters
          align="center"
          :justify="
            $vuetify.breakpoint.smAndDown ? 'space-between' : 'space-between'
          "
        >
          <v-col cols="4" md="2">
            <router-link
              :to="tableId && $route.name !== 'ThankYou' ? `/menu` : ''"
            >
              <base-img
                class="mb-1"
                style="max-width: 170px"
                :src="require('@/assets/logo-soleluna.png')"
                contain
              />
            </router-link>
          </v-col>
          <v-col
            cols="3"
            v-if="
              tableId &&
              $route.name !== 'ShoppingCart' &&
              $route.name !== 'Welcome' &&
              $route.name !== 'ThankYou' &&
              $route.name !== 'Bookings' &&
              $route.name !== 'Bookings-Thanks'
            "
          >
            <v-row justify="end" align="center">
              <v-btn
                class="white--text"
                small
                text
                elevation="0"
                height="48px"
                @click="callWaiter"
              >
                {{ $t("callWaiter") }}
              </v-btn>
            </v-row>
          </v-col>
          <v-col cols="3" v-if="$route.name == 'Bookings'">
            <v-row justify="end" align="center">
              <v-btn
                class="white--text"
                small
                text
                elevation="0"
                height="48px"
                to="/menu"
              >
                VER CARTA
              </v-btn>
            </v-row>
          </v-col>
          <v-col cols="4" md="3">
            <v-row class="justify-center align-center">
              <v-speed-dial v-model="speedDial" :direction="'bottom'">
                <template v-slot:activator>
                  <v-btn fab color="transparent" text v-model="speedDial">
                    <img
                      :alt="$i18n.locale"
                      height="40"
                      :src="require(`@/assets/flags/${$i18n.locale}.png`)"
                      style="border-radius: 9999px; cursor: pointer"
                      width="40"
                    />
                  </v-btn>
                </template>

                <v-btn
                  v-for="(text, lang) in filteredLanguages"
                  :key="lang"
                  text
                  fab
                >
                  <img
                    :alt="text"
                    height="40"
                    :src="require(`@/assets/flags/${lang}.png`)"
                    style="border-radius: 9999px; cursor: pointer"
                    width="40"
                    @click="setLang(lang, true)"
                  />
                </v-btn>
              </v-speed-dial>
              <UserMenu />
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </v-app-bar>
  </div>
</template>

<script>
import { axiosInstance } from "@/plugins/axios";
import { i18n } from "@/plugins/i18n";
import { languages, loadLanguageAsync } from "@/plugins/lang";

export default {
  name: "HomeAppBar",
  components: {
    UserMenu: () => import("@/components/userOrLogin/UserMenu.vue"),
  },
  computed: {
    filteredLanguages() {
      const lang = { ...languages };
      delete lang[this.$i18n.locale];
      return lang;
    },
  },
  data: () => ({
    tableId: "",
    speedDial: false,
    lang: localStorage.getItem("lang") || navigator.language.split("-")[0],
    languages: { ...languages },
  }),
  async mounted() {
    this.getTableId();
    const globalConfigs = await axiosInstance.get("api/v1/settings/global/", {
      headers: {
        Authorization: `Token ${process.env.VUE_APP_TOKEN}`,
        "Content-Type": "application/json",
        Site: process.env.VUE_APP_SITE,
        Client: "app",
      },
    });
    const { language_web_menu, currency } = globalConfigs.data.data;
    if (language_web_menu) {
      this.lang = language_web_menu;
      await this.$store.dispatch("user/setCurrency", currency);
    }
    await this.setLang(this.lang);
  },
  watch: {
    $route() {
      this.getTableId();
    },
    deep: true,
  },
  methods: {
    async setLang(lang, reloadWindow = false) {
      this.lang = lang;
      await loadLanguageAsync(lang)
        .then(() => {
          let formData = new FormData();
          formData.append("language_web_menu", lang);
          axiosInstance
            .put("api/v1/settings/global/", formData, {
              headers: {
                Authorization: `Token ${process.env.VUE_APP_TOKEN}`,
                "Content-Type": "application/json",
                Site: process.env.VUE_APP_SITE,
                Client: "app",
              },
            })
            .then(() => {
              if (!reloadWindow) return;
              i18n.locale = this.lang;
              location.reload();
            });
        })
        .catch(() => {
          const payload = {
            msg: i18n.t("languageNotAdded"),
            success: false,
          };
          this.$store.commit("user/OPEN_SNACKBAR", payload);
        });
    },

    getTableId() {
      this.tableId = localStorage.getItem("tableId");
    },
    callWaiter() {
      const headers = {
        headers: {
          Authorization: `Token ${process.env.VUE_APP_TOKEN}`,
          "Content-Type": "application/json",
          Site: process.env.VUE_APP_SITE,
          Client: "App",
          Accept: "*/*",
        },
      };
      const id = localStorage.getItem("tableId");
      axiosInstance
        .get(`/api/v1/commands/call/${id}/`, headers)
        .then(() => {
          const payload = {
            msg: i18n.t("waiterCalled"),
            success: true,
          };
          this.$store.commit("user/OPEN_SNACKBAR", payload);
        })
        .catch((e) => {
          const payload = {
            msg: e.response.data.detail
              ? e.response.data.detail
              : i18n.t("problem"),
            success: false,
          };
          this.$store.commit("user/OPEN_SNACKBAR", payload);
        });
    },
  },
};
</script>

<style lang="sass">
$tab-font-size: map-deep-get($headings, 'body-2', 'size') !important
#home-app-bar
  z-index: 100

  .v-tabs-slider
    max-width: 24px
    margin: 0 auto

  .v-tab
    &::before
      display: none
</style>
