var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-app-bar',{attrs:{"id":"home-app-bar","sticky":"","color":"secondary","elevation":"5","height":"80"}},[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',{attrs:{"no-gutters":"","align":"center","justify":_vm.$vuetify.breakpoint.smAndDown ? 'space-between' : 'space-between'}},[_c('v-col',{attrs:{"cols":"4","md":"2"}},[_c('router-link',{attrs:{"to":_vm.tableId && _vm.$route.name !== 'ThankYou' ? "/menu" : ''}},[_c('base-img',{staticClass:"mb-1",staticStyle:{"max-width":"170px"},attrs:{"src":require('@/assets/logo-soleluna.png'),"contain":""}})],1)],1),(
            _vm.tableId &&
            _vm.$route.name !== 'ShoppingCart' &&
            _vm.$route.name !== 'Welcome' &&
            _vm.$route.name !== 'ThankYou' &&
            _vm.$route.name !== 'Bookings' &&
            _vm.$route.name !== 'Bookings-Thanks'
          )?_c('v-col',{attrs:{"cols":"3"}},[_c('v-row',{attrs:{"justify":"end","align":"center"}},[_c('v-btn',{staticClass:"white--text",attrs:{"small":"","text":"","elevation":"0","height":"48px"},on:{"click":_vm.callWaiter}},[_vm._v(" "+_vm._s(_vm.$t("callWaiter"))+" ")])],1)],1):_vm._e(),(_vm.$route.name == 'Bookings')?_c('v-col',{attrs:{"cols":"3"}},[_c('v-row',{attrs:{"justify":"end","align":"center"}},[_c('v-btn',{staticClass:"white--text",attrs:{"small":"","text":"","elevation":"0","height":"48px","to":"/menu"}},[_vm._v(" VER CARTA ")])],1)],1):_vm._e(),_c('v-col',{attrs:{"cols":"4","md":"3"}},[_c('v-row',{staticClass:"justify-center align-center"},[_c('v-speed-dial',{attrs:{"direction":'bottom'},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-btn',{attrs:{"fab":"","color":"transparent","text":""},model:{value:(_vm.speedDial),callback:function ($$v) {_vm.speedDial=$$v},expression:"speedDial"}},[_c('img',{staticStyle:{"border-radius":"9999px","cursor":"pointer"},attrs:{"alt":_vm.$i18n.locale,"height":"40","src":require(("@/assets/flags/" + (_vm.$i18n.locale) + ".png")),"width":"40"}})])]},proxy:true}]),model:{value:(_vm.speedDial),callback:function ($$v) {_vm.speedDial=$$v},expression:"speedDial"}},_vm._l((_vm.filteredLanguages),function(text,lang){return _c('v-btn',{key:lang,attrs:{"text":"","fab":""}},[_c('img',{staticStyle:{"border-radius":"9999px","cursor":"pointer"},attrs:{"alt":text,"height":"40","src":require(("@/assets/flags/" + lang + ".png")),"width":"40"},on:{"click":function($event){return _vm.setLang(lang, true)}}})])}),1),_c('UserMenu')],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }